// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-cammy-index-js": () => import("../src/pages/work/cammy/index.js" /* webpackChunkName: "component---src-pages-work-cammy-index-js" */),
  "component---src-pages-work-eurovision-index-js": () => import("../src/pages/work/eurovision/index.js" /* webpackChunkName: "component---src-pages-work-eurovision-index-js" */),
  "component---src-pages-work-nirovision-index-js": () => import("../src/pages/work/nirovision/index.js" /* webpackChunkName: "component---src-pages-work-nirovision-index-js" */),
  "component---src-pages-work-paramount-index-js": () => import("../src/pages/work/paramount/index.js" /* webpackChunkName: "component---src-pages-work-paramount-index-js" */),
  "component---src-pages-work-race-index-js": () => import("../src/pages/work/race/index.js" /* webpackChunkName: "component---src-pages-work-race-index-js" */),
  "component---src-pages-work-universal-index-js": () => import("../src/pages/work/universal/index.js" /* webpackChunkName: "component---src-pages-work-universal-index-js" */)
}

